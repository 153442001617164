import React from 'react';
import images from '../../images/instructions/';
import Step from '../../components/step';
import GlobalStyles from '../../styles/GlobalStyles';
import Card from '../../components/card';
import tw from 'twin.macro';

const IndexPage = () => {
    return (
        <GlobalStyles>
            <div tw="bg-gray-200">
                <div tw="py-12">
                    <Card>
                        <p tw="text-center">[ THESE INSTRUCTIONS ARE STILL UNDER CONSTRUCTION ]</p>
                    </Card>
                    <Card>
                        <p>- Glue may be helpful when assembling the arcade. We reccommend cyanoacrylate ("Super glue").</p>
                        <p>- Painting the assembly (after Step 15) before applying stickers may be helpful with the final look of the arcade.</p>
                        <p>- The laser-engraved numbers should always be on the <span tw="italic font-bold">inside</span> of the assembly.</p>
                    </Card>
                    <Step number={1} image={images.step01}>
                        <p>
                            Insert Top Panel <span className="part-number">2</span> into Left Panel <span className="part-number">1</span>.
                        </p>
                        <div className="note-box">
                            <p><span tw="font-bold">Note: </span>Do not remove the rectangular cutout in Left Panel <span className="part-number">1</span> unless you are using the Raspberry Pi screen accessories.</p>
                        </div>
                    </Step>

                    <Step number={2} image={images.step02}>
                        <p>
                            Insert Marquee Bezel <span className="part-number">3</span> into Left Panel <span className="part-number">1</span>.
                        </p>
                    </Step>

                    <Step number={3} image={images.step03}>
                        <p>
                            Insert Top Panel <span className="part-number">4</span> into Left Panel <span className="part-number">1</span> making sure that the notch faces the front of the arcade.
                        </p>
                    </Step>

                    <Step number={4} image={images.step04}>
                        <p>
                            Insert Screen Bezel <span className="part-number">5</span> into Left Panel <span className="part-number">1</span>. Make sure that thicker side of the bezel faces the bottom of the arcade.
                        </p>
                    </Step>

                    <Step number={5} image={images.step05}>
                        <p>
                            Insert Screen Support <span className="part-number">6</span> into Left Panel <span className="part-number">1</span>.
                        </p>
                    </Step>

                    <Step number={6} image={images.step06}>
                        <p>
                            Insert Right Panel <span className="part-number">7</span> onto the current assembly.
                        </p>
                        <p>
                            It sometimes helps to start at the top of the arcade when connecting each tab.
                        </p>
                    </Step>

                    <Step number={7} image={images.step07}>
                        <p>
                            Insert Floor Panel <span className="part-number">8</span> onto the two Side Panels.
                        </p>
                        <p>
                            Make sure that the angled tabs labeled <span className="part-number">9</span>, <span className="part-number">10</span>, and <span className="part-number">11</span> point toward the front of the arcade.
                        </p>
                    </Step>

                    <Step number={8} image={images.step08}>
                        <p>
                            Insert Lower Center Panel <span className="part-number">9</span> onto the front of the arcade.
                        </p>
                    </Step>

                    <Step number={9} image={images.step09a}>
                        <p>
                            Insert Lower Right Panel <span className="part-number">10</span> onto the front of the arcade.
                        </p>
                        <p>
                            The easiest method is to slide it in on the outside edge and rotate inward until both tabs are connected.
                        </p>
                    </Step>

                    <Step number={10} image={images.step10a}>
                        <p>
                            Insert Lower Left Panel <span className="part-number">11</span> onto the front of the arcade using the same method.
                        </p>
                        <p>
                            Slide the panel into the outside edge and rotate inward until both tabs are connected.
                        </p>
                    </Step>

                    <Step number={11} image={images.step10b}>
                        <p>
                            Confirm that all panels are fully seated and connected as shown.
                        </p>
                    </Step>

                    <Step number={12} image={images.step11}>
                        <p>
                            Separately, connect Top Control Panel <span className="part-number">13</span> into Right Control Panel <span className="part-number">12</span>.
                        </p>
                    </Step>

                    <Step number={13} image={images.step12}>
                        <p>
                            Insert Front Control Panel <span className="part-number">14</span> into Right Control Panel <span className="part-number">12</span>.
                        </p>
                    </Step>

                    <Step number={14} image={images.step13}>
                        <p>
                            Insert Left Control Panel <span className="part-number">15</span> onto panels <span className="part-number">13</span> and <span className="part-number">14</span> to finish the Control Board.
                        </p>
                    </Step>

                    <Step number={15} image={images.step14a}>
                        <p>
                            Slide the completed Control Board into the slots between the Screen Bezel <span className="part-number">5</span> and Screen Support <span className="part-number">6</span>.
                        </p>
                        <p>Confirm all the panels are fully seated and connected.</p>
                    </Step>

                    <Card>
                        <p> - optional: paint the arcade kit (show parts: cabinet, control, screen, back, marquee)</p>
                        <p> - optional: apply artwork (template/stickers)</p>
                    </Card>

                    <Step number={16} image={images.step15}>
                        <p>
                            Slide in the Marquee Panel <span className="part-number">14</span> behind the Marquee Bezel <span className="part-number">3</span> with the tab pointed downward.
                        </p>
                        <p>
                            The panel should slide down until the tab seats fully into the provided slot behind the marquee bezel.
                        </p>
                    </Step>

                    <Step number={17} image={images.step16}>
                        <p>
                            Slide in the Screen Border and Screen Panel from behind. They  should sit directly behind the Screen Bezel <span className="part-number">5</span> and on top of Screen Support <span className="part-number">6</span>.
                        </p>
                    </Step>

                    <Step number={18} image={images.step17a}>
                        <p>
                            Slide a Holding Tab <span className="part-number">A</span> into the slot near the lower right corner of the Screen Panel with the Tab pointing upward.
                        </p>
                    </Step>

                    <Step number={19} image={images.step17b}>
                        <p>
                            Slide a Holding Tab <span className="part-number">A</span> into the slot near the upper left corner of the Screen Panel with the Tab pointing downward.
                        </p>
                    </Step>

                    <Step number={20} image={images.step18}>
                        <p>
                            Attach the final Back Panel onto the full assembly with the larger scoop at the top.
                        </p>

                        <p>
                            We <span tw="italic font-bold">don't</span> recommend adding glue to this piece as you may need to remove the Back Panel later.
                        </p>
                    </Step>

                    <Step number={21} image={images.step00}>
                        <p>
                            Sit back and admire your new desktop mini arcade. You did it!
                        </p>
                    </Step>

                    <Card>
                        <div tw="max-w-lg mx-auto my-4 px-4">
                            <h2 tw="text-iltms-orange font-bold text-3xl mb-2 uppercase">Thank You!</h2>
                            <p>Thank you so much for beta testing the mini arcade for us! Your feedback is <span tw="italic">VERY</span> important in helping us make this product better.</p>
                            <p>If you can, please fill out the following form to tell us about your experience.</p>
                            <a href="https://forms.monday.com/forms/01422b3dcd3db7e0a69fae2f854a994a?r=use1" rel="noopener noreferrer" target="_blank"><button tw="block mt-8 bg-iltms-orange text-white text-center w-full py-3 px-6 text-2xl rounded shadow-md">Fill Out the Form</button></a>
                        </div>
                    </Card>

                </div>
            </div>
        </GlobalStyles>
    )
}

export default IndexPage