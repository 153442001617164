import tw from 'twin.macro';
import styled from 'styled-components';

const StepStyles = styled.div`

    h2 {
        color: #ff6b00;
        ${tw`font-bold text-3xl uppercase mb-2`};
    }

    .part-number {
        ${tw`
            font-mono
            px-1 
            py-0.5 
            mr-1 
            border 
            text-gray-600 
            border-gray-300 
            bg-gray-100 
            rounded
        `};
    }

    .note-box {
        ${tw`
            mt-6
            border
            border-gray-300
            bg-gray-100
            p-4
            rounded
            text-gray-700
        `};

        p {
            ${tw`text-base`};
        }
    }

    p {
        ${tw`text-xl`};
        line-height: 2rem;
    }

`;

export default StepStyles;