import React from 'react'
import Card from './card';
import tw from 'twin.macro';
import StepStyles from './step.styles';

export default function Step(props) {


    return (
        <StepStyles>
            <Card>
                <div tw="lg:flex">
                    <div tw="lg:w-2/3">
                        <div tw="max-w-screen-md mx-auto">
                            <img tw="max-w-full mx-auto" src={props.image} />
                        </div>
                    </div>
                    <div tw="border-t pt-12 px-4 lg:w-1/3 lg:border-t-0 lg:border-l border-gray-200 lg:pl-12 lg:pr-0 lg:pt-0">
                        <h2>Step {props.number || 0}</h2>
                        {props.children}
                    </div>
                </div>
            </Card>
        </StepStyles>
    )
}
