import React from 'react';
import tw from "twin.macro";
import CardStyles from "./card.styles";

const card = (props) => {
    return (
        <CardStyles tw="container mx-auto px-4">
            <div tw="bg-white my-4 p-4 pb-8 sm:p-8 sm:my-8 md:my-12 md:mx-4 md:p-12 shadow-md rounded-md">
                {props.children}
            </div>
        </CardStyles>
    )
}

export default card
