import step00 from "./00 - Start - 2.png";
import step01 from "./01 - TopP LeftP - 2.png";
import step02 from "./02 - Bezel LeftP - 2.png";
import step03 from "./03 - SpeakerP LeftP - 2.png";
import step04 from "./04 - ScreenB LeftP - 2.png";
import step05 from "./05 - WishB LeftP - 2.png";
import step06 from "./06 - RightP - 2.png";
import step07 from "./07 - FloorP - 2.png";
import step08 from "./08 - Low Mid - 2.png";
import step09a from "./09a - Low Right - 2.png";
import step09b from "./09b - Low Right - 2.png";
import step10a from "./10a - Low Left - 2.png";
import step10b from "./10b - Low Left - 2.png";
import step11 from "./11 - Control Top + Right - 2.png";
import step12 from "./12 - Control Front - 2.png";
import step13 from "./13 - Control Left - 2.png";
import step14a from "./14a - Control Slide Start - 2.png";
import step14b from "./14b - Control Slide End - 2.png";
import step15 from "./15 - Marquee - 2.png";
import step16 from "./16 - Screen - 2.png";
import step17a from "./17a - Tab 1 - 2.png";
import step17b from "./17b - Tab 2 - 2.png";
import step18 from "./18 - Back - 2.png";

const images = {
    step00,
    step01,
    step02,
    step03,
    step04,
    step05,
    step06,
    step07,
    step08,
    step09a,
    step09b,
    step10a,
    step10b,
    step11,
    step12,
    step13,
    step14a,
    step14b,
    step15,
    step16,
    step17a,
    step17b,
    step18
};

export default images;